import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`THE MOST EXTENSIBLE PLATFORM TO BUILD IN-APP CHAT`}</p>
    <p>{`Bring delightful conversational experiences to your apps in hours—not days`}</p>
    <p>{`Get your free account
Contact sales`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      